import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import note from '../images/create-collaborate@1x.jpg'
import note1 from '../images/create-create@1x.jpg'
import note2 from '../images/create-disrupt@1x.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import { ZendeskAPI } from '../zendexconfig';
import PricingSlide from './PricingSlide';
import {customWebsitePackages,wordpressWebsitePackages,ecommerceWebsitePackages,mobileAppPackages} from '../data'

const Pricing = () => {
  const handleZendeskChat = () => {
    ZendeskAPI('webWidget', 'open');
  };
  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
  const images = [
    note,
    note1,
    note2,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImageIndex((prevIndex) =>
        (prevIndex + 1) % images.length
      );
    }, Math.floor(Math.random() * 3000) + 1000);

    return () => clearInterval(interval);
  }, [images.length]);
  const serviceSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    margin: 10,
    slideTransition: 'linear',
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: true,
    // rtl: true,
    loop: true,
    slidesToShow: 3, // Adjust the number of slides to show as needed
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1, // Adjust for smaller screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // Adjust for medium-sized screens
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1, // Adjust for medium-sized screens
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2, // Adjust for medium-sized screens
        },
      },
    ],
  };
  return (
    <div>
      <section className='pricing-page'
        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${images[backgroundImageIndex]})`,
          transition: 'background-image 1s ease-in-out',
          position: 'relative',
        }}>
        <div className="opacity">
          <div className="container-fluid">
            <div className="row pb-5 align-items-center justify-content-center">
              <div class="pro-heading-1"><h2>OUR COMPETITIVE PACKAGES</h2>
                <p>With our unique website design services, you can achieve customized excellence that is required to achieve your digital goals and boost your online presence.</p>

              </div>
              <div className="col-12 col-md-3">
                <div className="d-flex reo justify-content-center align-items-start">
                  <div className="nav flex-column main-host nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button className="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                      Cutom Websites
                    </button>
                    <button className="nav-link " id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                      Wordpress
                    </button>

                    <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                      E-Commerce
                    </button>
                    <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                      Apps
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-9">
                <div className="tab-content" id="v-pills-tabContent">

                  <div className="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                    <div className="container p-0">
                      <div className="slider-wrapper" data-aos="zoom-in-down" data-aos-duration="2000" data-aos-delay="0" data-aos-offset="0">
                        <div id="service-slider" className="owl-theme owl-carousel">
                          <Slider {...serviceSliderSettings}>
                            {customWebsitePackages.map((pkg, ind) => (
                              <div key={ind} className="item">
                                <div className={`slide-item ${ind === 0 ? 'promo' : ''}`}>
                                  <PricingSlide pkg={pkg} handleZendeskChat={handleZendeskChat} />
                                </div>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                    <div className="container ">
                      <div className="row justify-content-between">
                        <div className="col-12">
                          <div className="slider-wrapper" data-aos="zoom-in-down" data-aos-duration="2000" data-aos-delay="0" data-aos-offset="0">
                            <div id="service-slider" className="owl-theme owl-carousel">
                              <Slider {...serviceSliderSettings}>
                                {wordpressWebsitePackages.map((pkg, ind) => (
                                  <div key={ind} className="item">
                                    <div className="slide-item">
                                      <div className="main-custom-cards">
                                        <div className="main-oral">
                                          <h2>{pkg.title}</h2>
                                          <h6>{pkg.Original}<span>{pkg.originalPrice}</span></h6>
                                          <h3>{pkg.price}</h3>
                                        </div>
                                        <ul className="main-li">
                                          {pkg.listItem.map((list, index) => {
                                            return <li key={index}>{list}</li>
                                          })}
                                        </ul>
                                        {/* <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" className='loop-1'>view more</a> */}
                                        <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" className='loop-2'>order now</a>
                                        <div className="order-now">
                                          <button className='commercial-rollup' onClick={handleZendeskChat}>Live Chat</button>                                          <a href="tel:510-474-0516">510-474-0516</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade " id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                    <div className="container p-0">
                      <div className="slider-wrapper" data-aos="zoom-in-down" data-aos-duration="2000" data-aos-delay="0" data-aos-offset="0">
                        <div id="service-slider" className="owl-theme owl-carousel">
                          <Slider {...serviceSliderSettings}>
                            {ecommerceWebsitePackages.map((pkg, ind) => (
                              <div key={ind} className="item">
                                <div className="slide-item">
                                  <div className="main-custom-cards">
                                    <div className="main-oral">
                                      <h2>{pkg.title}</h2>
                                      <h6>{pkg.Original}<span>{pkg.originalPrice}</span></h6>
                                      <h3>{pkg.price}</h3>
                                    </div>
                                    <ul className="main-li">
                                      {pkg.listItem.map((list, index) => {
                                        return <li key={index}>{list}</li>
                                      })}
                                    </ul>

                                    <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" className='loop-2'>order now</a>
                                    <div className="order-now">
                                      <button className='commercial-rollup' onClick={handleZendeskChat}>Live Chat</button>                                      <a href="tel:510-474-0516">510-474-0516</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade " id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                    <div className="container p-0">
                      <div className="slider-wrapper" data-aos="zoom-in-down" data-aos-duration="2000" data-aos-delay="0" data-aos-offset="0">
                        <div id="service-slider" className="owl-theme owl-carousel">
                          <Slider {...serviceSliderSettings}>
                            {mobileAppPackages.map((pkg, ind) => (
                              <div key={ind} className="item">
                                <div className="slide-item">
                                  <div className="main-custom-cards">
                                    <div className="main-oral">
                                      <h2>{pkg.title}</h2>
                                      <h6>{pkg.Original}<span>{pkg.originalPrice}</span></h6>
                                      <h3>{pkg.price}</h3>
                                    </div>
                                    <ul className="main-li">
                                      {pkg.listItem.map((list, index) => {
                                        return <li key={index}>{list}</li>
                                      })}
                                    </ul>

                                    <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" className='loop-2'>order now</a>
                                    <div className="order-now">
                                      <button className='commercial-rollup' onClick={handleZendeskChat}>Live Chat</button>
                                      <a href="tel:510-474-0516">510-474-0516</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="main-button">
              <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
                Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
              </a>
              <a className='second-btn' href="tel:510-474-0516" >
                510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
              </a>


            </div>          </div>
        </div>

      </section>
    </div>
  );
};

export default Pricing;
