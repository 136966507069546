import React from 'react'
import m66 from '../images/02-slide.webp'
import m67 from '../images/shutterstock_1659838801-1-1536x1526.webp'
import m68 from '../images/we-love-what-we.png'
import m69 from '../images/we-build-partnerships.png'
import m70 from '../images/your-customer-is-our-customer.png'
import m71 from '../images/our-process-is-proven.png'

import m109 from '../images/bg-video.mp4'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import Form from './Form'


const Aboutus = () => {
    return (
        <div>
            <section className='about-banner'>
                <div className="container">
                    <div className="row">
                        <h1>About Us</h1>
                    </div>
                </div>

            </section>

            <section className='first-about'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12">
                            <div className="purchase">
                                <img className='img-fluid' src={m66} alt="no-img" />
                            </div>
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="purchase-1">
                                <h3>Our Mission</h3>
                                <p>For the past ten years, we have collaborated with our customers on national and worldwide platforms to assist them in achieving their full financial potential through effective and creative design. Your goals become our goals. We use every skill we have, covering graphic and web design disciplines, enabling our studio to offer you a comprehensive design service.</p>

                                <ul>
                                    <li>
                                        <a class="quote" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Request A Quote <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />  </a>

                                    </li>
                                    <li>  <a class="call-quote" href="tel:510-474-0516">510-474-0516 <FontAwesomeIcon className='roter-w2' icon={faPhone} shake /></a></li>
                                </ul>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            
            <section className='new-about'>
                <video className='addon-prod' autoPlay loop muted src={m109}></video>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-12">
                            <div className="tech-switch">
                                <h4>Why Partner With Us?</h4>
                                <p>Selecting Virtual Design Crew as your partner for design and development indicates that you choose innovation, technical expertise, customization, and teamwork. As leaders in the field, we are aware that selecting the appropriate partner is essential to the accomplishment of your initiatives. We have four compelling reasons why choosing to work with us is the best option for your needs in design and development.
</p>
                            </div>

                        </div>
                        <div className="col-md-6 col-12">
                            <div className="main-bank">
                                <div className="bank-1">
                                    <img className='img-fluid' src={m68} alt="no-img" />
                                </div>
                                <div className="bank-2">
                                    <h3>Innovative Design </h3>
                                    <p>Our team, motivated by a desire to be creative, creates trends rather than following them. Choose Virtual Design Crew to gain access to an abundance of design knowledge and make sure your projects are above industry standards. Our commitment is to use innovation to bring your ideas to life, whether it is through UI/UX or visually striking websites.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="main-bank">
                                <div className="bank-1">
                                    <img className='img-fluid' src={m69} alt="no-img" />
                                </div>
                                <div className="bank-2">
                                    <h3>Collaborative Approach</h3>
                                    <p>Our ethos revolves around collaboration. In close collaboration with our clients, we create exceptional design and development solutions by merging your vision with our experience. You are a vital element of the process, not just a client. The process from concept to completion is made entertaining and rewarding by frequent updates and feedback sessions.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="main-bank">
                                <div className="bank-1">
                                    <img className='img-fluid' src={m70} alt="no-img" />
                                </div>
                                <div className="bank-2">
                                    <h3>Customized Solutions </h3>
                                    <p>Virtual Design Crew provides a customized strategy since it acknowledges the uniqueness of every project. Our versatile workforce caters to your unique demands regardless of whether you're an individual entrepreneur, startup, or an established company. We take the time to get to know your goals, audience, and brand to provide solutions that are specifically tailored to your vision.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="main-bank">
                                <div className="bank-1">
                                    <img className='img-fluid' src={m71} alt="no-img" />
                                </div>
                                <div className="bank-2">
                                    <h3>IT-Driven Development</h3>
                                    <p>It's critical to keep ahead in the rapidly evolving IT sector. Our programming team is proficient in the newest frameworks and technologies, so your projects are not only up-to-date but also secure for the future. Working with us gives you access to a wealth of technical knowledge for a strong, safe, and future-ready online presence, from responsive web development to innovative mobile apps.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className='first-about'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-12">
                            <div className="purchase-1">
                                <h3>Our Vission</h3>
                                <p>We are constantly working on our design and development process to give an informative, user-friendly, and successful strategy to provide businesses with an idea or goal they want to achieve. This design and development approach is designed to accommodate small, medium, and large-scale companies' and business’ needs.
</p>
                            </div>
                            <div className="main-button">
                                <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
                                    Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
                                </a>
                                <a className='second-btn' href="tel:510-474-0516" >
                                    510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
                                </a>


                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="purchase">
                                <img className='img-fluid' src={m67} alt="no-img" />
                            </div>
                        </div>


                    </div>
                </div>
            </section>


            <Form />
        </div>
    )
}

export default Aboutus
