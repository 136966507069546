import React, { useEffect, useRef } from 'react'
import backdoor from '../images/blur.avif'
import { gsap } from "gsap";

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Stepup() {
  const shadeImage = useRef();

  useEffect(() => {
    gsap.fromTo(
      shadeImage.current,
      {
        scale: 0.1,
        x: '0%'
        // autoAlpha: 0, // If you want to control opacity
      },
      {
        scale: 1,
        x: '0%',
        // autoAlpha: 1, // If you want to control opacity
        scrollTrigger: {
          trigger: shadeImage.current,
          id: 'scrollingAnimation',
          scrub: true,
          start: 'top 100%',
          // markers: true, // for debugging
          end: 'bottom 0%',
        },
      }
    );
  }, []);

  return (
    <div>
      <section className='step-4' style={{ paddingBottom: '100px' }}>
        <div className="container">
          <div className="row g-3 romp justify-content-space">
            <div>
            <img id="scollingAnimation" ref={shadeImage} src={backdoor} className="img-fluid bg-shade" alt="img" />
            </div>
            <div className="col-12 col-lg-12 ">
              <div className="step-banner-text">
                <h2>Develop Your Brand Using Our Distinctive Approach to Custom Websites Development
                </h2>
                <p className='theme-p'>Your Vision, Our Experience in Creating Custom Websites</p>
              </div>
            </div>

            <div className="row  justify-content-center">
              <div className="col-12 col-md-6">
                <div className="custom-laps">
                  <p>"We design customized websites that are suited to your company's specific requirements, ensuring a unique online presence."</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="custom-laps">
                  <p>"Use strategic digital transformation to integrate cutting-edge technologies and advance your company."</p>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <div className="custom-laps">
                  <p>"Our focus on SEO increases your website's exposure and traffic from search engines, guaranteeing your online success."</p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="custom-laps">
                  <p>"Engage in a cooperative relationship that features open communication to make sure your goal is met through gradual progress."</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  )
}

export default Stepup
