import Form from "./Form"
import contactBg from '../images/contact-bg.jpg'

const Contact = () => {
    return (
        <>
           <section className='portf852-banner' style={{backgroundImage: `url(${contactBg})`}}>
        <div className="container">
          <div className="row">
            <div className="works-942">

              <h1>CONNECT WITH US,  
                <span className='bravo'> TURNING PASSION </span>INTO COLLABORATION </h1>
              <p>Embrace Innovation, Release Creativity: The Transition from Passion to Creative thinking</p>
            </div>
            <div className="port-btn">
              <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" class="port-btn1">
                Get Started
              </a>

            </div>
          </div>
        </div>

      </section>
        <Form />
        </>
    )
}
export default Contact