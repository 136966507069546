import React, { useState, useEffect } from 'react'
import note from '../images/create-collaborate@1x.jpg'
import note1 from '../images/create-create@1x.jpg'
import note2 from '../images/create-disrupt@1x.jpg'
import m71 from '../images/e-commer-apps-screens copy.png'
import m72 from '../images/fully-responsive-icon.png'
import m73 from '../images/flag-ship-design-icon.png'
import m74 from '../images/snapy-icon.png'
import m75 from '../images/DEVICES.png'
import m76 from '../images/WordPress971.png'
import m95 from '../images/bricksy-screenshot-1.webp'
import m96 from '../images/screencapture-demo-anarieldesign-bricksy-travel-2023-10-25-22_30_49.png'
import m97 from '../images/screenshot8.webp'
import m98 from '../images/screencapture-858.png'
import m99 from '../images/epentatonic-woo.webp'
import m100 from '../images/screencapture-fse-.png'
import m1 from '../images/imgpsh_fullsize_anim85.png'
import m2 from '../images/imgpsh_fullsize_anim858.png'
import m398 from '../images/l1.png'
import m392 from '../images/l1c.png'
import m498 from '../images/l-2.png'
import m492 from '../images/l-2c.png'
import m598 from '../images/l-3.png'
import m592 from '../images/l-3c.png'
import m698 from '../images/l-4.png'
import m692 from '../images/l-4c.png'

// porfilio
import { Fancybox } from "@fancyapps/ui";
import m85 from '../images/beleco2.webp'
import m86 from '../images/inbbb.png'
import m87 from '../images/screenshot.webp'
import m88 from '../images/word-1.png'
import m91 from '../images/scentina2.webp'
import m92 from '../images/screencapture-.png'
import m93 from '../images/screenshot1.webp'
import m94 from '../images/screencapture-organicthemes.png'

import Form from './Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import PricingSlide from '../components/PricingSlide'
import { wordpressWebsitePackages } from '../data'
import { ZendeskAPI } from '../zendexconfig';


const Wordpress = () => {
  const handleZendeskChat = () => {
    ZendeskAPI('webWidget', 'open');
  };

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
    });
}, []);

  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
  const images = [
    note,
    note1,
    note2,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImageIndex((prevIndex) =>
        (prevIndex + 1) % images.length
      );
    }, Math.floor(Math.random() * 3000) + 1000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    
    <div>

      <section className='word-banner'>
        <div className="container">
          <div className="row">
            <div className="characer">
              <h1>Build Custom Wordpress Website With <span className='bravo'>Virtual Design Crew</span></h1>
              <p>You've got brilliant ideas and are managing a profitable company. It's time to update your website to reflect this.</p>
            </div>

            <div className="port-btn">
              <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" class="port-btn1">
                contact us
              </a>

            </div>

          </div>
        </div>
      </section>

      <section className='opener-include'>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">

              <div className="number-presword">
                <h3>
                Simplified Client Management Using a Custom WordPress Plugin
                </h3>
                <p>We developed a customized plugin for subscription-data mapping, added administrative filters for improved client subscription management, and customized the Memberpress WordPress plugin to allow multi-business registration. </p>
              </div>
              <div className="main-button">
                <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
                  Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
                </a>
                <a className='second-btn' href="tel:510-474-0516" >
                  510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
                </a>


              </div>

            </div>

            <div className="col-md-6 col-12">
              <img className='img-fluid' src={m76} alt="no-img" />
            </div>

          </div>
        </div>
      </section>

      <section className='draw-side'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <div className="big-amount">
                <h2>
                Get some of the best WordPress website developers to create a custom website.
                </h2>
                <div className="bullets-line">
                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m72} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>WordPress Theme Development</h4>
                      <p>We create premium WordPress themes that aren't bulky or limiting. </p>
                    </div>


                  </div>

                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m73} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>Plugins for WordPress</h4>
                      <p>We start from the beginning when creating customized WordPress plugins to meet your particular needs. </p>
                    </div>


                  </div>

                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m74} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>WordPress Migration</h4>
                      <p>Migrate to or from WordPress without fear of data loss, breaches, or downtime.</p>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="checking">
                <img className='righting-img' src={m71} alt="no-img" />
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='working-process'>
        <div className="container">
          <div className="row pb-4 pt-4 g-4">

            <div className="laboris">
  
            <h5>How We Work </h5>
            <h3>It Is Simple To Work With Us</h3>

            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>1.</h3>
                    <h4>Discovery & Planning:</h4>
                    <p>During this phase, we interact with clients to comprehend their objectives, intended audience, and needs. To specify the project's features, functions, and scope, we carry out a thorough examination. The foundation for a customized WordPress solution that supports the client's goals is established at this stage.</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>2.</h3>
                    <h4>Design & Wireframing:</h4>
                    <p>Our creative design team creates visual representations from conceptual ideas. The structure of the website and user interactions are outlined in wireframes. Before moving on with development, we collaborate extensively with customers to make sure the design is consistent with their brand identity. We do this by sending mockups and prototypes for approval.</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>3.</h3>
                    <h4>Development & Configuration:</h4>
                    <p>We start the coding process by making use of WordPress's features. Our developers provide responsive and efficient performance while integrating customized functionality into the design to bring it to life. We set the necessary integrations and plugins, focusing on scalability and future-proofing the website for changing requirements.
</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>4.</h3>
                    <h4>Content Integration & SEO:</h4>
                    <p>The next stage is to add client-provided content to the website or work with our writing team to create unique material. We make sure the website is not only aesthetically pleasing but also easily discoverable by optimizing each page for search engines. This improves the website's online presence.
</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>5.</h3>
                    <h4>Testing & Quality Assurance:
</h4>
                    <p>To ensure that the website functions properly on a variety of devices and browsers, comprehensive testing is carried out. In addition to making sure that the website satisfies industry security standards, we inspect it for errors, malfunctions, and performance problems. To address client comments and make the necessary adjustments, user acceptance testing is carried out.
</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>6.</h3>
                    <h4>Deployment & Maintenance:
</h4>
                    <p>The website is launched on the client's server after approval. We guarantee a smooth launch and provide assistance with domain setting. Following the launch, we offer continuous support and maintenance services, including security audits, performance monitoring, and updates to make sure the website keeps up with changing trends in technology.
</p>
                  </div>
            </div>
          </div>
        </div>
      </section>


      <section className='prim-porfolio'>
        <div className="container">
          <div className="row">
            <div className="noise-sharing">
              <h2>Our Work</h2>
              <p>We develop and build amazing experiences that fascinate audiences and drive continuous engagement.</p>
            </div>
   
            <div className="row pt-4 justify-content-between">
                                    <div className="col-12 col-md-3">
                                        <div className="invoke">
                                            <a href={m86} data-fancybox="gallery" >
                                                <img src={m85} className="img-fluid" alt="Image1" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="invoke">
                                            <a href={m88} data-fancybox="gallery" >
                                                <img src={m87} className="img-fluid" alt="Image1" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="invoke">
                                            <a href={m92} data-fancybox="gallery" >
                                                <img src={m91} className="img-fluid" alt="Image1" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="invoke">
                                            <a href={m94} data-fancybox="gallery" >
                                                <img src={m93} className="img-fluid" alt="Image1" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-4 justify-content-between">
                                        <div className="col-12 col-md-3">
                                            <div className="invoke">
                                                <a href={m96} data-fancybox="gallery" >
                                                    <img src={m95} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="invoke">
                                                <a href={m98} data-fancybox="gallery" >
                                                    <img src={m97} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="invoke">
                                                <a href={m100} data-fancybox="gallery" >
                                                    <img src={m99} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="invoke">
                                                <a href={m2} data-fancybox="gallery" >
                                                    <img src={m1} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row pt-4 justify-content-between">
                                        <div className="col-12 col-md-3">
                                            <div className="invoke">
                                                <a href={m398} data-fancybox="gallery" >
                                                    <img src={m392} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="invoke">
                                                <a href={m498} data-fancybox="gallery" >
                                                    <img src={m492} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="invoke">
                                                <a href={m598} data-fancybox="gallery" >
                                                    <img src={m592} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <div className="invoke">
                                                <a href={m698} data-fancybox="gallery" >
                                                    <img src={m692} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>

                                    </div>
          </div>



          <div className="main-button expire-button">
            <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
              Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
            </a>
            <a className='second-btn' href="tel:510-474-0516" >
              510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
            </a>


          </div>
        </div>


      </section >

      <section className='pricing-page'

        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${images[backgroundImageIndex]})`,
          transition: 'background-image 1s ease-in-out',
          position: 'relative',
        }}>


        <div className="opacity">
          <div className="container">
            <div className="row pb-5">
            <div class="pro-heading-1"><h2>OUR COMPETITIVE PACKAGES</h2>
                <p>With our unique website design services, you can achieve customized excellence that is required to achieve your digital goals and boost your online presence.</p>

              </div>
              {wordpressWebsitePackages.map((pkg, ind) => {
                return <div className="col-lg-4 col-12">
                     
                  <PricingSlide pkg={pkg} handleZendeskChat={handleZendeskChat} />
                </div>
              })}
            </div>
          </div>
          <div className="main-button">
              <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
                Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
              </a>
              <a className='second-btn' href="tel:510-474-0516" >
                510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
              </a>


            </div>  
        </div>

       
      </section>


      <section className='center-first'>
        <div className="container">
          <div className="row">
            <div className="together">
              <h3>Get a Free Quote for WordPress Website Development</h3>
              <p>Our staff will respond to your questions, provide recommendations, and provide you with a full WordPress scope, pricing estimate, and project timeframe. We are excited to talk about your project.
</p>
              <img className='img-fluid' src={m75} alt="no-img" />
            </div>
          </div>
        </div>

      </section>

      <Form />


    </div>
  )
}

export default Wordpress
