import React from 'react'

const TermsCondition = () => {
  return (
    <div>

<section class="working-terms">
    <div class="container">
        <div class="row">


            <h2 class="working-that"> Revision Policy</h2>
            <p>Revisions are dependent on the package purchased. Customers can contact us for as many free revisions as
                they would like, and as long as the design and concept are preserved, we will make changes to their
                design without charging more. There will be a 48-hour turnaround for revisions.
            </p>


            <h2 class="working-that"> Refund Policy</h2>

            <h2 class="working-that"> Refund policy will be void if,</h2>

            <p>•	The primary design concept has been approved.</p>
            <p>•	You have demanded revisions.</p>
            <p>•	Urgent design projects are non-refundable.</p>
            <p>•	The cancellation has been made due to reasons non-related to the company.</p>
            <p>•	The client is unresponsive or out of contact for more than 2 weeks of the project.</p>
            <p>•	Company's policies, or policy, have been violated.</p>
            <p>•	Other company or designer has been approached for the same project.</p>
            <p>•	The creative brief is lacking the required information.</p>
            <p>•	A complete design change has been demanded.</p>
            <p>•	The claim has crossed the given 'request for refund' time span.</p>
            <p>•	The business is closing or changing its name or business.</p>
            <p>•	Reasons such as 'change of mind', 'disagreement with partners, or other reasons that do not pertain to the service will not be subject to refund under any circumstances.</p>
            <p>•	If a client subscribes to a service bundle and happens to be dissatisfied with a particular service, a refund will only be applicable to that particular service and 'not' the entire bundle.</p>
            <p>•	No refund can be claimed/processed once after the final files have been delivered to you following your request.</p>
            <p>•	Refund and revision explicitly do not apply to items offered free.</p>
            <p>•	Services including but not limited to Social Media, SEO, Domain Registration, and Web Hosting are not entitled to a refund under any circumstances.</p>
            <p>•	In-case of websites, refunds will not be entertained once the client has approved the design and the website is sent for development.</p>
            <p>•	Refund will not be applicable on the work outsourced by a third party.</p>






            <p>All refund claims are subject to the following schedule:
            </p>
            <p>In the unlikely event that you request a refund prior to receiving the first design concepts, you will be
                eligible for a complete refund (minus a 10% handling and preparation fee).</p>
            <p>In order to complete the refund, you must get in touch with us within 7 working days following the
                original order. Refund requests cannot be fulfilled after the customer has been inactive for 7 working
                days. There will be a price associated with renewing the order, which will depend on your venture.</p>
            <p>All refund requests have to be submitted to the support team. Due to the violation of your user
                agreement, Virtual Design Crew reserves the right to approve or reject your request on an individual
                basis.</p>
            <p>Before a design project is completed, the client must provide input. For this reason, it is necessary to
                have the customer participate actively in the process and provide feedback in order to achieve the
                desired outcomes.</p>
            <p>If it is determined that the logo created by Virtual Design Crew is very similar to an existing design,
                you are eligible for a new logo thanks to a unique design guarantee. </p>

            <p>Any resemblance to an existing outline will be accidental, and Virtual Design Crew will not accept
                liability or demand payment in such an instance. Obtaining copyright protection for their artwork is the
                client's obligation.</p>
                <h2 class="working-that">Newsletter Opt-out</h2>
                <p>No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties".</p>
            <h2 class="working-that"> How To Claim Your Refund</h2>
            <p>Please ensure that you complete the following requirements to guarantee that your refund request is
                processed correctly and authorized.</p>

            
            <p>1. Let us know what's wrong and use one of the following three methods to get your money back:The
                toll-free number is <strong>510-474-0516.</strong> In accordance with our revision policy, we will
                attempt to address
                your question and concern right away; if not, we will email you a refund request approval from our
                refund department. Following the payment, the Virtual Design Crew would receive ownership of your
                design, and you would be prohibited from displaying publicly any version of the design that the company
                provided you.
            </p>
            <p>2. As the design rights will now be transferred to Virtual Design Crew, you agree that you will not have
                any ownership interest in, or right to utilize any response or other substance, work item, or media,
                either directly or indirectly.</p>
            <p>3. The Virtual Design Crew would collaborate with government copyright agencies to exchange copyright
                acquisition data for the reimbursed outlines, which would restrict the designs' future use as original
                works. </p>
            <p>Please contact us at<a href="mailto:support@virtualdesigncrew.com">support@virtualdesigncrew.com</a> if
                you have any questions or complaints regarding our refund policy.

            </p>

            <h2 class="working-that"> 100% Satisfaction Guarantee</h2>
            <p>Depending on your package, we rework the requested design and keep reworking on it till you are
                completely satisfied.</p>

            <h2 class="working-that"> Domain and Hosting</h2>
            <p>When packages are purchased, a free domain and hosting are included.</p>
            <p>You can set up any email account that comes with the website bundle using email software from other
                parties, including Outlook.</p>
            <p>You won't be given email accounts if you decide not to host the website with us.</p>


            <h2 class="working-that"> Record Maintenance</h2>
            <p>After we give your final files, we maintain an archive of your final design. We can email the final files
                to you upon request if you would want to get them again.</p>

            <h2 class="working-that"> Customer Support </h2>
            <p>We provide customer service that is available 24/7 to answer your questions.
                We welcome your contact at any time and guarantee a timely response.
            </p>
            <h2 class="working-that"> Policy for Correspondence</h2>
            <p>YOU agree that Virtual Design Crew shall not be held responsible for any correspondence from email
                addresses other than those that are followed by our specific area, such as
                "<a href="mailto:support@virtualdesigncrew.com">support@virtualdesigncrew.com</a>" or from any toll-free
                number that is not listed on our website. </p>
            <p>
                Virtual Design Crew should not be held responsible for any harm that results from this email. We simply
                take on responsibility for any correspondence sent to the email address(es) listed under our own space
                name, the toll-free number listed on the Virtual Design Crew website, or both.
                We at Virtual Design Crew guarantee that every one of our logos is created from scratch. We will create
                a logo for you that is skillful and fully complies with your design specifications.

            </p>
        </div>
    </div>
</section>

      
    </div>
  )
}

export default TermsCondition
