import React from 'react'
import paper1 from '../images/paper-1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight,faPhone} from '@fortawesome/free-solid-svg-icons'

function Paperbanner() {
    return (
        <div>
            <section className="paper-banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="session">
                                <h6>About Virtual Design Crew</h6>
                                <h2>Your Web Solutions Partner for Transparent, Visionary Solutions.
                                </h2>
                                <p>At Virtual Design, we redefine digital excellence through custom website design and development. With a love of creativity, we craft unique virtual identities that are all customized to our client's specific requirements. Our strategic approach and integration of modern technologies will make your website stable and visually appealing. Our focus is on SEO and creating a transparent, collaborative process to transform your vision into a digital masterpiece. We optimize visibility and traffic from search engines for your website's success.

                                    </p>                                
                                <ul>
                                    <li>
                                        <a class="quote" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Request A Quote <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />  </a>
                                      
                                    </li>
                                    <li>  <a class="call-quote" href="tel:510-474-0516">510-474-0516 <FontAwesomeIcon className='roter-w2' icon={faPhone} shake /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="session-img">
                                <img className='img-fluid' src={paper1} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Paperbanner
