import React from 'react'
import loop from '../images/loop1.png'

function Testimonails() {
    return (
        <div>
            <section className="miantest">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                    <div className="main-test">
                                <h2 className='aspects-9651'> Frequently Asked Questions</h2>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="tester">
                                    <img src={loop} className='img-fluid' alt="img" />
                                </div>
                            </div>
                        <div className="col-12 col-md-8">
                          
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        How much time will it take to develop a custom website?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                           <p>The complexity, features, and input from clients all affect timelines. The process we use usually takes a few weeks up to a few months.
</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        What input can I have in the process of design? 

                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Your opinions are very important. To make sure the website reflects your vision, we involve you in consultations, design reviews, and feedback sessions.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Are you able to search engine optimize my website? 
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                          <p>Absolutely. We incorporate SEO best practices during development to increase your site's visibility and boost its search engine rating. </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingfoure">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefoure" aria-expanded="false" aria-controls="collapsefoure">
                                        Do you offer ongoing support after the website goes live?

                                        </button>
                                    </h2>
                                    <div id="collapsefoure" class="accordion-collapse collapse" aria-labelledby="headingfoure" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Yes, we provide support after launch. We're here to make sure your website stays optimized and up-to-date whether it involves integrating features, upgrading it, or troubleshooting.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimonails
