import React, { useState, useEffect } from 'react'
import note from '../images/create-collaborate@1x.jpg'
import note1 from '../images/create-create@1x.jpg'
import note2 from '../images/create-disrupt@1x.jpg'
import m71 from '../images/e-commer-apps-screens copy.png'
import m72 from '../images/fully-responsive-icon.png'
import m73 from '../images/flag-ship-design-icon.png'
import m74 from '../images/snapy-icon.png'
import m75 from '../images/png-tran.png'
import m76 from '../images/image_2023_07_27T21_28_46_180Z.png'

// portfolio 
import { Fancybox } from "@fancyapps/ui";
import m25 from '../images/t-3.png'
import m26 from '../images/4.png'
import m27 from '../images/tr-1.png'
import m28 from '../images/tr-45.png'
import m29 from '../images/12-min.png'
import m30 from '../images/12-t-min.png'
import m31 from '../images/13-t-min.png'
import m32 from '../images/13-min.png'
import m33 from '../images/mock-1.png'
import m34 from '../images/mock-2.png'
import m35 from '../images/revert-1.png'
import m36 from '../images/revert-2.png'
import m37 from '../images/10-t-min.png'
import m38 from '../images/10-min.png'
import m40 from '../images/11-t-min.png'
import m39 from '../images/11-min.png'
import m792 from '../images/l-5c.png'
import m798 from '../images/l-5.png'
import m892 from '../images/l6.png'
import m898 from '../images/l6c.png'
import m998 from '../images/l-9c.png'
import m992 from '../images/l-9.png'
import m1098 from '../images/l11.png'
import m1092 from '../images/l11c.png'

import Form from './Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import PricingSlide from '../components/PricingSlide'
import { shopifyAppPackages } from '../data'
import { ZendeskAPI } from '../zendexconfig';


const ShopifyWebsite = () => {
  const handleZendeskChat = () => {
    ZendeskAPI('webWidget', 'open');
  };

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
    });
  }, []);

  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
  const images = [
    note,
    note1,
    note2,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImageIndex((prevIndex) =>
        (prevIndex + 1) % images.length
      );
    }, Math.floor(Math.random() * 3000) + 1000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (

    <div>

      <section className='shopefy267-banner'>
        <div className="container">
          <div className="row">
            <div className="characer">
              <h1>Hire Virtual Design Crew's Top <span className='bravo'>Shopify Developers</span></h1>
              <p>As one of the first three Shopify Plus Partners, we have developed a team of skilled Shopify developers who build sites for the top e-commerce brands in the world.</p>
            </div>
            <div className="port-btn">
              <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" class="port-btn1">
                contact us
              </a>

            </div>
          </div>
        </div>
      </section>

      <section className='opener-include'>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">

              <div className="number-presword">
                <h3>
                  Let Our Shopify Web Developers To Create Your Online Store
                </h3>
                <p>Do you want to create a website using Shopify? We at Virtual Design Crew have extensive knowledge in eCommerce and have worked with both Shopify and the Shopify Plus platform. Our professionals will guide you through customization services because we have experience with Shopify design, development, and marketing. We'll examine your eCommerce store's objectives and choose the best course of action to achieve them.</p>
              </div>
              <div className="main-button">
                <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
                  Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
                </a>
                <a className='second-btn' href="tel:510-474-0516" >
                  510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
                </a>


              </div>

            </div>

            <div className="col-md-6 col-12">
              <img className='img-fluid' src={m76} alt="no-img" />
            </div>

          </div>
        </div>
      </section>

      <section className='draw-side'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <div className="big-amount">
                <h2>
                  Speak With One Of Our Shopify Developers By Giving Us A Call
                </h2>
                <div className="bullets-line">
                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m72} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>Customization of Shopify Websites</h4>
                      <p>Do you want to change the features or style of your Shopify store? There's no other place to look. We can revitalize your website.</p>
                    </div>


                  </div>

                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m73} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>Personalized Features & Design</h4>
                      <p>We can help your project go smoothly, whether you want to add a theme on top of the existing capabilities or want new development.</p>
                    </div>


                  </div>

                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m74} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>Marketing Powered by Shopify</h4>
                      <p>We have over 20 years of experience in search marketing, so we know all there is to know about the Shopify platform and how to get you to the top of the results.</p>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="checking">
                <img className='righting-img' src={m71} alt="no-img" />
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='working-process'>
        <div className="container">
          <div className="row pb-4 pt-4 g-4">

            <div className="laboris">

              <h5>How We Work </h5>
              <h3>It Is Simple To Work With Us</h3>

            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>1.</h3>
                <h4>Discovery Phase:</h4>
                <p>We work together with clients to understand your brand, objectives, and target market in this first phase. We define the project scope, highlight essential features, and create a development and design roadmap for a successful Shopify website through in-depth talks and research.</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>2.</h3>
                <h4>Wireframing & Design:</h4>
                <p>We provide wireframes and design concepts that are in line with the client's vision and industry best practices by building on the insights obtained. This stage focuses on creating a user-friendly interface and making sure the Shopify website is responsive, aesthetically pleasing, and represents the business.</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>3.</h3>
                <h4>Customized Development:</h4>
                <p>Our development team takes over once the design is approved. We start the development process and use effective and clean code practices to execute the design. For seamless operation and peak performance, the Shopify platform is tailored to match exactly what is mentioned in the project scope.</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>4.</h3>
                <h4>Content Integration:</h4>
                <p>Once the framework is in place, we integrate interesting and search engine optimized content. Images, product descriptions, and any other relevant information are included here. Our team makes sure that the Shopify website properly conveys the client's message and looks fantastic, which increases user engagement and search engine visibility.
</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>5.</h3>
                <h4>Testing & Quality Assurance:</h4>
                <p>Thorough testing is done to find and fix any defects or problems. Our team of quality assurance specialists makes sure that the Shopify website works perfectly on a range of devices and browsers. This step includes security tests, performance testing, and user acceptance testing to ensure a solid and dependable final product.</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>6.</h3>
                <h4>Launch & Optimization:</h4>
                <p>We launch the Shopify website after client approval and successful testing. After the launch, our staff collects user input, keeps an eye on performance, and makes any necessary changes. We aim for continuous improvement by examining user behavior and making calculated changes to improve the overall performance and usefulness of the website.
</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='prim-porfolio'>
        <div className="container">
          <div className="row">
            <div className="noise-sharing">
              <h2>Our Work</h2>
              <p>We develop and build amazing experiences that fascinate audiences and drive continuous engagement.</p>
            </div>
            <div className="row pt-4 justify-content-between">
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m26} data-fancybox="gallery" >
                    <img src={m25} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m27} data-fancybox="gallery" >
                    <img src={m28} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m29} data-fancybox="gallery" >
                    <img src={m30} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m32} data-fancybox="gallery" >
                    <img src={m31} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row pt-4 justify-content-between">
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m33} data-fancybox="gallery" >
                    <img src={m34} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m36} data-fancybox="gallery" >
                    <img src={m35} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m38} data-fancybox="gallery" >
                    <img src={m37} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m39} data-fancybox="gallery" >
                    <img src={m40} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row pt-4 justify-content-between">
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m798} data-fancybox="gallery" >
                    <img src={m792} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m892} data-fancybox="gallery" >
                    <img src={m898} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m992} data-fancybox="gallery" >
                    <img src={m998} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="invoke">
                  <a href={m1098} data-fancybox="gallery" >
                    <img src={m1092} className="img-fluid" alt="Image1" />
                  </a>
                </div>
              </div>
            </div>

          </div>



          <div className="main-button expire-button">
            <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
              Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
            </a>
            <a className='second-btn' href="tel:510-474-0516" >
              510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
            </a>


          </div>
        </div>


      </section >

      <section className='pricing-page'

        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${images[backgroundImageIndex]})`,
          transition: 'background-image 1s ease-in-out',
          position: 'relative',
        }}>


        <div className="opacity">
          <div className="container">
            <div className="row pb-5">
              <div class="pro-heading-1"><h2>OUR COMPETITIVE PACKAGES</h2>
                <p>With our unique website design services, you can achieve customized excellence that is required to achieve your digital goals and boost your online presence.</p>

              </div>
              {shopifyAppPackages.map((pkg, ind) => {
                return <div className="col-lg-4 col-12">

                  <PricingSlide pkg={pkg} handleZendeskChat={handleZendeskChat} />
                </div>
              })}
            </div>
          </div>
          <div className="main-button">
            <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
              Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
            </a>
            <a className='second-btn' href="tel:510-474-0516" >
              510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
            </a>


          </div>
        </div>


      </section>


      <section className='center-first'>
        <div className="container">
          <div className="row">
            <div className="together">
              <h3>Our Shopify Stores Perform <br />Better Than Other Stores</h3>
              <p>Do you require a professional Shopify website? We have managed dozens of eCommerce development and design projects over the past 20+ years, helping clients in increasing their online revenues.</p>
              <img className='img-fluid' src={m75} alt="no-img" />
            </div>
          </div>
        </div>

      </section>

      <Form />


    </div>
  )
}

export default ShopifyWebsite
