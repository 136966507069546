import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div>
            <section class="working-terms">

                <div class="container">
                    <div class="row">

                        <h2 class="working-that"> About The Policy</h2>
                        <p>The security of our clients is our first priority at Virtual Design Crew, and we treat their information
                            as if it were our own. Although we do collect customer data, we only utilize it to improve our client
                            administration services. Our company is aware of our responsibility to protect and use the data of our
                            clients. The information that our clients provide to us online is NEVER leased or offered by us.</p>
                        <p>This policy explains how we use the personal information we collect about our customers, why we collect
                            it, and how we use it. We outline the choices you can make on how we collect and use your data in our
                            plan.</p>

                        <h2 class="working-that"> Personal Information Collected</h2>
                        <p>The information that Virtual Design Crew collects from customers includes their name, email address,
                            postal code, and phone number. These are bits of information that we receive from customers when they
                            request or provide their personal data to our firm. We may also respond to comments, inquiries, and the
                            like using the email addresses or phone numbers that we obtain through our mailing system, such as our
                            Contact Us Form.</p>
                        <p>Additionally, our company maintains a record of the items that have previously aroused the interest of
                            our clients as well as their online purchases.</p>

                        <h2 class="working-that"> Use of Collected Data</h2>
                        <p>The collected data is used in a variety of improved methods. Our company uses the information that our
                            clients have spared to fulfill their request. We also send them messages to confirm the request, and in
                            the event that our company has any additional questions about the request, our client services
                            representatives may also contact them by phone, street address, or email.</p>
                        <p>Users can also receive redesigns for our website and services, which may include information about
                            advancements and a brochure. Additionally, we might use the information about your purchases and
                            interests to improve our site design and the customer's purchasing history.</p>

                        <h2 class="working-that"> Newsletter Opt-out</h2>
                        <p>No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties". </p>

                        <h2 class="working-that"> Social Media (Features) and Widgets</h2>
                        <p>Social media features like the Facebook Like button and widgets like the Share this button and
                            intelligent small-scale programs that run continuously on our website are integrated into our website.
                            These features have the potential to collect your IP address, the page you are viewing on our website,
                            and to create a cookie in order to enable the feature to function properly. Widgets and online
                            networking features are either enabled on our site directly or through a third party. Your participation
                            with these features is reflected in the organization providing it's protection strategy.</p>

                        <h2 class="working-that"> 3rd Party Sharing</h2>
                        <p>Personal information won't be shared with third parties unless permission is given in written. We don't
                            give personal information to outside parties in any situation.</p>
                        <p>We bill you for services using credit card processing providers. These organizations don't possess,
                            exchange, keep, or use personally identifiable information for any other reason. On our website, we also
                            offer live customer service chat using ZopimChat. These companies are authorized to use your personal
                            information solely as needed to fulfill our requests for services.
                        </p>

                        <h2 class="working-that"> Security of Personal Information</h2>
                        <p>Because Secure Sockets Layer (SSL) software encrypts client data during transmission, we can guarantee
                            the security of our customers' information.</p>
                        <p>We protect the personal information that is supplied to us by adhering to well accepted industry models,
                            both during transmission and after it reaches us. Besides, there is no 100% safe method for electronic
                            storing or transmission over the Internet. As a result, even while we make every effort to use
                            financially sound methods to secure your personal information, we are unable to guarantee complete
                            security.</p>
                        <p>If you have any questions about security on our website, you may contact us by email at
                            <a href="mailto:support@virtualdesigncrew.com">support@virtualdesigncrew.com</a>
                        </p>

                        <h2 class="working-that"> Access of Registered Accounts</h2>
                        <p>The client only needs to sign in on our landing page to access their enrolled accounts. The consumer can
                            see their historical records and data that they have already given to the website once they have been
                            checked in. After logging in to their records, clients can modify their data by selecting My Account.
                        </p>

                        <h2 class="working-that"> Changing and Deleting/Unsubscribing Accounts</h2>
                        <p>To request the cancellation of their membership or to have their personal data deleted, customers must
                            send an email. This should prevent the customer from responding to any communication or messages
                            pertaining to any online requests they may have made through the website.</p>
                        <p>For as long as your record is active or performs as anticipated to benefit you, we will retain your data.
                            If you would like your record to be deleted or if you would like us to stop using your data to provide
                            you with benefits, it would be best if you could get in contact with us. We will retain and use your
                            information as necessary to comply with our legal obligations, settle disputes, and maintain our
                            agreements.
                        </p>

                        <h2 class="working-that"> Cookies and Their Use</h2>
                        <p>Cookies are unique alphanumeric codes that are transferred to clients' hard disks via their online
                            applications. This enables our models to understand the programs and contents that customers have loaded
                            into their shopping carts during their visits.
                        </p>
                        <p>For information on how to prevent the program from accepting additional cookies, how to have the program
                            notify the client when another cookies is received, or how to impede treats both internally and
                            externally, check out the Help section of the toolbar on the majority of applications. In any event,
                            treats let you take advantage of the most popular features on our website, and our local recommendation
                            is that customers leave them to be recognized.</p>

                        <p>Secondly, we track your IP address to identify server problems and to manage our website. In addition,
                            your IP address is used to gather broad demographic information about you, such as your region and
                            Internet service provider. Additionally, we might compile aggregated data on our customers' website
                            usage. This could include information on the site's movement designs and search queries. Personally
                            Identifiable Information is not associated with an IP address or log record data (PII).
                        </p>
                        <p>Thirdly, we record program rankings, access durations, the URLs visitors used to reach our website, and
                            the URLs they viewed while on our website. However, as our Privacy Policy generally states, we only
                            provide this data to linked structures and do not share it with other parties.</p>

                        <p>The use of cookies by our affiliates, subsidiaries, after-sale companies, and management providers is not
                            covered by our privacy statement. These treats are not within our control or admission. Session ID
                            treatments are used by our allies, partners, following service organizations, and administration
                            suppliers to make it easier for you to use our website.
                        </p>

                        <h2 class="working-that"> Clear Gifs (Web Beacons/Web Bugs)</h2>
                        <p>We use a product innovation called clear gifs (also known as Web Beacons/Web Bugs), which inform us of
                            the successful material on our webpage, allowing us to better manage content. Clear gifs are tiny images
                            with a unique identifier that are similar to candies in size and are used to monitor Web clients' online
                            activities. Treats are stored on a customer's computer hard disk, whereas clear gifs are loaded on
                            websites covertly and are roughly equivalent to the period at the end of this line. We don't link the
                            information gathered by clear gifs to personally identifying information about our clients.</p>

                        <h2 class="working-that"> Testimonials</h2>
                        <p>We may publish your testimonial with your name if you give us permission. Please contact us if you would
                            like your testimonial to be removed.</p>

                        <h2 class="working-that"> Links to Other Web Sites</h2>
                        <p>Connections on our site link to other websites, whose privacy policies might be different from ours. If
                            you provide personal information to any of such locations, your information is governed by their privacy
                            statements. We strongly advise you to carefully read any security declaration found on a website you
                            visit.
                            .</p>
                        <h2 class="working-that"> Notice of the Changes in Privacy Policy</h2>
                        <p>If we choose to change our security approach, we will notify you of the changes on this security
                            statement, the landing page, and other locations we think appropriate so that you are aware of the
                            information we collect, how we use it, and, if applicable, the circumstances in which we disclose it. We
                            reserve the right to modify this protection statement at any time, so please review it often. In the
                            unlikely event that we implement significant enhancements to this strategy, we will notify you via this
                            page, via email, or through a notice mechanism on our landing page before the modification produces any
                            adverse consequences.</p>

                        <h2 class="working-that"> Legal Disclaimer</h2>
                        <p>We claim all legal power to disclose your personally identifiable information as mandated by law and when
                            we believe it is necessary to protect our rights and/or comply with a court order, ongoing legal action,
                            or other lawful process delivered on our website.
                        </p>
                        <h2 class="working-that"> Questions</h2>
                        <p>Please contact us by email at <a
                            href="mailto:support@virtualdesigncrew.com">support@virtualdesigncrew.com</a> or by phone at our toll-free number,
                            <strong> 510-474-0516</strong>, if you have any questions about our privacy policy or how we use your data.</p>


                        <h2 class="working-that"> Cancellation Policy.</h2>
                        <p>

                            To cancel a subscription or request that their personal information be removed the client must write an email.<br />
                            This will stop the user from getting emails about any orders they may have placed online from the website. <br />
                            For as long as your account is active or as long as it's necessary to provide you services, we'll retain your information. <br />
                            If you would like us to stop using your information to provide you services, or if you would like to terminate your account, please get in touch with us. <br />
                            Your information will be kept on file and used only as needed to fulfill legal requirements, settle disputes, and uphold agreements.<br />
                            Only requests for cancellations submitted within 24 hours of placing the purchase and before the project's execution has begun will be taken into consideration.<br />
                            The Subscription Services Agreement may be canceled by the Subscriber at any time. To terminate services, send a written notice to  <a href="mailto:support@virtualdesigncrew.com">support@virtualdesigncrew.com</a>. <br />
                            All services provided throughout the subscription time must be paid for by the subscriber. There will be no reimbursement of the Subscriber's yearly subscription price.
                        </p>

                    </div>
                </div>
            </section>
        </div>
    )
}


export default PrivacyPolicy
