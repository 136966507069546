import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Thankyoumuch = () => {
  return (
    <>
        <div className="eleventh">
        <h2>ThankYou</h2>
          <p>We have received your online inquiry and forwarded your information to one of our <br/>Account Directors who will be in touch with you shortly.</p>
          <p>We look forward to working with you</p>
          <button onClick={() => window.history.back}>
            <h5><FontAwesomeIcon className='count-6987' icon={faArrowLeft} />Back to Home</h5>
            </button>

        </div>
    </>
  )
}

export default Thankyoumuch;
