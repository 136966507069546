import React from 'react'

import Portfolio from '../components/Portfolio'
import Form from './Form'


const portfolio = () => {
  return (
    <div>

      <section className='portf852-banner'>
        <div className="container">
          <div className="row">
            <div className="works-942">

              <h1>Ignite Your Imagination, 
                <span className='bravo'> Transforming Passion </span>into Innovation</h1>
              <p>Embrace Innovation, Release Creativity: The Transition from Passion to Creative thinking</p>
            </div>
            <div className="port-btn">
              <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" class="port-btn1">
                contact us
              </a>

            </div>
          </div>
        </div>

      </section>

      <Portfolio />
      <Form />
    </div>
  )
}

export default portfolio
