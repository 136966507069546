import React, { useState, useEffect } from 'react'
import note from '../images/create-collaborate@1x.jpg'
import note1 from '../images/create-create@1x.jpg'
import note2 from '../images/create-disrupt@1x.jpg'
import m71 from '../images/e-commer-apps-screens copy.png'
import m72 from '../images/fully-responsive-icon.png'
import m73 from '../images/flag-ship-design-icon.png'
import m74 from '../images/snapy-icon.png'
import m75 from '../images/chamion-removebg-preview.png'
import m76 from '../images/banner-img-mob-aap-dev.png'

// porflio 
import { Fancybox } from "@fancyapps/ui";
import m13 from '../images/1-f.png'
import m14 from '../images/mki.png'
import m15 from '../images/kli.png'
import m16 from '../images/uiy.png'
import m17 from '../images/mbm.png'
import m18 from '../images/fuya.png'
import m19 from '../images/poi.png'
import m20 from '../images/4-f.png'
import m21 from '../images/5.png'
import m22 from '../images/5-f.png'
import m23 from '../images/6-f.png'
import m24 from '../images/6.png'

import Form from './Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import PricingSlide from '../components/PricingSlide'
import { mobileAppPackages } from '../data'
import { ZendeskAPI } from '../zendexconfig';


const MobileApp = () => {
  const handleZendeskChat = () => {
    ZendeskAPI('webWidget', 'open');
  };

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
    });
}, []);

  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
  const images = [
    note,
    note1,
    note2,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImageIndex((prevIndex) =>
        (prevIndex + 1) % images.length
      );
    }, Math.floor(Math.random() * 3000) + 1000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    
    <div>

      <section className='mbl-appbanner'>
        <div className="container">
          <div className="row">
            <div className="characer">
              <h1>DELIVERING AMAZING MOBILE APPLICATION DESIGN & <span className='bravo'> DEVELOPMENT SERVICES</span></h1>
              <p>We work with international brands to design and develop cutting-edge digital products that offer seamless user interfaces across all existing platforms and gadgets.</p>
            </div>
            <div className="port-btn">
              <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" class="port-btn1">
                contact us
              </a>

            </div>

          </div>
        </div>
      </section>

      <section className='opener-include'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">

              <div className="number-presword">
                <h3>
                Why Choose Virtual Design Crew for Your Mobile App Development
                </h3>
                <p>Virtual Design Crew has over 750 specialists and has completed over 350 mobile projects successfully, showcasing its commitment to creative problem-solving. More than 130 happy customers have left favorable reviews for their apps on the App Store and Google Play, where they regularly obtain a 4+ rating. As leaders in the field, we guarantee expertise and client satisfaction by setting the benchmark for mobile app development.
</p>
              </div>
              <div className="main-button">
                <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
                  Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
                </a>
                <a className='second-btn' href="tel:510-474-0516" >
                  510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
                </a>


              </div>

            </div>

            <div className="col-md-6 col-12">
              <img className='img-fluid' src={m76} alt="no-img" />
            </div>

          </div>
        </div>
      </section>

      <section className='draw-side'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <div className="big-amount">
                <h2>
                We provide full-cycle implementation services and help with specific stages of mobile application development.
                </h2>
                <div className="bullets-line">
                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m72} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>Product Design</h4>
                      <p>To identify and make visible your competitive edge, we examine the market and your brand identity.
</p>
                    </div>


                  </div>

                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m73} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>Mobile App Consultancy</h4>
                      <p>We help with the completion of app concepts, offer platform and device compatibility guidance, schedule project tasks, and minimize development expenses.
</p>
                    </div>


                  </div>

                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m74} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>Mobile Application Designs</h4>
                      <p>To guarantee conversion, engagement, and simple adoption, our designers provide a visually appealing user interface (UI) and a simple, intuitive UX.</p>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="checking">
                <img className='righting-img' src={m71} alt="no-img" />
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='working-process'>
        <div className="container">
          <div className="row pb-4 pt-4 g-4">

            <div className="laboris">
  
            <h5>How We Work </h5>
            <h3>It Is Simple To Work With Us</h3>

            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>1.</h3>
                    <h4>Discovery and Planning: </h4>
                    <p>We begin by learning about your objectives, target audience, and desired features. We establish a thorough plan outlining the functionality, design, and technological stack of the mobile app through cooperative workshops.</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>2.</h3>
                    <h4>Design and Prototyping: 
</h4>
                    <p>Our skilled developers create wireframes and prototypes to guarantee an interface that is both aesthetically pleasing and easy to use. We refine the design iteratively in response to your feedback until it precisely reflects your vision.
</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>3.</h3>
                    <h4>Development: 
</h4>
                    <p>Our skilled developers code the software with scalability and efficiency in mind, bringing the design to existence. We create reliable and effective mobile applications by utilizing the latest innovations and following industry best practices.</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>4.</h3>
                    <h4>Testing & Quality Assurance: 
</h4>
                    <p>To find and fix any problems or issues, comprehensive testing is done. Our quality assurance team uses a range of testing techniques, including functional, usability, and performance testing, to guarantee a smooth and error-free user experience.</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>5.</h3>
                    <h4>Deployment: 
</h4>
                    <p>We oversee the deployment procedure, guaranteeing a seamless transfer from the development to the production settings. In order to ensure a smooth and timely app launch, we set up server environments, communicate with app stores, and use best practices.
</p>
                  </div>
            </div>
            <div className="col-md-4 col-12">
                  <div className="trusted">
                    <h3>6.</h3>
                    <h4>Maintenance & Support:</h4>
                    <p>After the launch, we offer continuous support, quickly resolving any problems and maintaining the app's compatibility with the newest security patches and technological advancements. We commit to regular updates, performance monitoring, and user feedback analysis.</p>
                  </div>
            </div>
          </div>
        </div>
      </section>

      <section className='prim-porfolio'>
        <div className="container">
          <div className="row">
            <div className="noise-sharing">
              <h2>Our Work</h2>
              <p>We develop and build amazing experiences that fascinate audiences and drive continuous engagement.</p>
            </div>
            <div className="row pt-4 justify-content-between">
                                        <div className="col-12 col-md-4">
                                            <div className="invoke">
                                                <a href={m13} data-fancybox="gallery" >
                                                    <img src={m14} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="invoke">
                                                <a href={m16} data-fancybox="gallery" >
                                                    <img src={m15} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <div className="invoke">
                                                <a href={m18} data-fancybox="gallery" >
                                                    <img src={m17} className="img-fluid" alt="Image1" />
                                                </a>
                                            </div>
                                        </div>

                                        <div className="row pt-4 justify-content-between">
                                            <div className="col-12 col-md-4">
                                                <div className="invoke">
                                                    <a href={m20} data-fancybox="gallery" >
                                                        <img src={m19} className="img-fluid" alt="Image1" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="invoke">
                                                    <a href={m22} data-fancybox="gallery" >
                                                        <img src={m21} className="img-fluid" alt="Image1" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="invoke">
                                                    <a href={m23} data-fancybox="gallery" >
                                                        <img src={m24} className="img-fluid" alt="Image1" />
                                                    </a>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

          </div>



          <div className="main-button expire-button">
            <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
              Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
            </a>
            <a className='second-btn' href="tel:510-474-0516" >
              510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
            </a>


          </div>
        </div>


      </section >

      <section className='pricing-page'

        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${images[backgroundImageIndex]})`,
          transition: 'background-image 1s ease-in-out',
          position: 'relative',
        }}>


        <div className="opacity">
          <div className="container">
            <div className="row pb-5">
            <div class="pro-heading-1"><h2>OUR COMPETITIVE PACKAGES</h2>
                <p>With our unique website design services, you can achieve customized excellence that is required to achieve your digital goals and boost your online presence.</p>

              </div>
              {mobileAppPackages.map((pkg, ind) => {
                return <div className="col-lg-4 col-12">
                     
                  <PricingSlide pkg={pkg} handleZendeskChat={handleZendeskChat} />
                </div>
              })}
            </div>
          </div>
          <div className="main-button">
              <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
                Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
              </a>
              <a className='second-btn' href="tel:510-474-0516" >
                510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
              </a>


            </div>  
        </div>

       
      </section>


      <section className='center-first'>
        <div className="container">
          <div className="row">
            <div className="together">
              <h3>OUR EXPERTISE WITH TECHNOLOGICALLY ADVANCED MOBILE APP DEVELOPMENT</h3>
              <p>Use our vast expertise working with all key technologies to create expressive and feature-rich native iOS / Android, cross-platform, or Progressive Web Apps that will wow your users.
</p>
              <img className='img-fluid' src={m75} alt="no-img" />
            </div>
          </div>
        </div>

      </section>

      <Form />


    </div>
  )
}

export default MobileApp
