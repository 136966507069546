import React from 'react'
import video from '../images/galaxy-stone.mp4'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


function Process() {
    return (
        <div>
            <section className="process">
                <video loop="ture" autoPlay muted>
                    <source src={video} type="video/mp4" />
                </video>
                <div className="container">
                    <div className="row pt-5 justify-content-between align-items-center">
                        <div className="col-12 col-md-4">
                            <div className="main-text-process">
                                <h6>
                                    Our Process
                                </h6>
                                <h2>The Five-Step  
                                    <span> Custom Website</span> Process by Virtual Design Crew.</h2>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="process-p">
                                <p>The progress of each project depends on the method used to complete it. An ongoing process leads to consistent results, quality, and business development. Even though every project we work on has a unique conclusion, we consistently follow the identical procedure to guarantee optimum results. When you work with Iceberg, we will guide you through every stage of creating a custom website or enhancing your current one, addressing any questions you may have. 
</p>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-5 pb-5 justify-content-between">
                        <div className="col-12 col-md-5">
                            <div className="custom-mater">
                                <div className="content">
                                <h4>Unique Design & Development 
</h4>
                                <p>
                                Our designers and developers work together to produce a visually attractive and highly functional website with a strategic roadmap in place.  </p>
                                </div>
                                <FontAwesomeIcon className='trof' icon={faArrowRight} />
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="custom-mater">
                            <div className="content">
                            <h4>Strategic Planning</h4>
                                <p>Our team creates a detailed strategy that outlines the technology, functionality, and design required for achieving your vision.</p>
                                </div>
                                
                                <FontAwesomeIcon className='trof' icon={faArrowRight} />
                            </div>
                        </div>
                    </div>
                    <div className="row pt-12 align-items-center">
                        <div className="col-12 col-md-4">
                            <div className="process-p">
                                <p>Consider these five steps from Virtual Design Crew to get started on a smooth digital transformation. From discovery to launch, our meticulous process puts a high focus on strategic planning and client engagement. 
</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="custom-mater">
                            <div className="content">
                            <h4>Research and Consultation</h4>
                                <p>We begin by getting an idea of what you want to accomplish. We gather information through in-depth meetings so that we can develop a plan that matches your goals and brand identity. </p>
                                </div>
                               
                                <FontAwesomeIcon className='trof' icon={faArrowRight} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="process-p">
                                <p>Our custom website development and design process at Virtual Design Crew is an adventure made specifically for your unique vision. Each step, from meticulous testing to creative thinking and strategic planning, is filled with knowledge. 
 </p>
                            </div>
                        </div>

                    </div>
                    <div className="row pt-5 pb-5 justify-content-between">
                        <div className="col-12 col-md-5">
                            <div className="custom-mater">
                            <div className="content">
                            <h4>Quality Assurance and Testing </h4>
                                <p>We bring your website through an extensive testing process that includes cross-browser compatibility and performance to ensure a trustworthy, functional end product.</p>
                                </div>
                              
                                <FontAwesomeIcon className='trof' icon={faArrowRight} />
                            </div>
                        </div>
                        <div className="col-12 col-md-5">
                            <div className="custom-mater">
                            <div className="content">
                                <h4>Launch & Continuous Support </h4>
                                <p>We launch websites accurately, and we provide continuous support to meet post-launch needs and adjust to the constantly shifting cyberspace.</p>
                                </div>
                              
                                
                                <FontAwesomeIcon className='trof' icon={faArrowRight} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Process