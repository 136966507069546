import React, { useState, useEffect } from 'react'
import note from '../images/create-collaborate@1x.jpg'
import note1 from '../images/create-create@1x.jpg'
import note2 from '../images/create-disrupt@1x.jpg'
import m71 from '../images/e-commer-apps-screens copy.png'
import m72 from '../images/fully-responsive-icon.png'
import m73 from '../images/flag-ship-design-icon.png'
import m74 from '../images/snapy-icon.png'
import m75 from '../images/hosoren.png'
import m76 from '../images/abc.webp'

// prorfolio images
import { Fancybox } from "@fancyapps/ui";
import remort25 from '../images/1-min.png';
import morely from '../images/1-t-min.png';
import r1 from '../images/2.png'
import top from '../images/pop1.png'
import top1 from '../images/t-1151.jpg'
import top2 from '../images/4-min.png'
import top3 from '../images/4-t-min.png'
import m3 from '../images/8-t-min.png'
import m4 from '../images/8-min.png'
import m5 from '../images/m-7.jpg'
import m6 from '../images/mt-2.jpg'
import m7 from '../images/mk.jpg'
import m8 from '../images/mk-1.png'
import m9 from '../images/9mm.png'
import m10 from '../images/9-t-min.png'
import m11 from '../images/3-t-min.png'
import m12 from '../images/3-min.png'
import troter from '../images/t-2.png'

import Form from './Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPhone } from '@fortawesome/free-solid-svg-icons'
import PricingSlide from '../components/PricingSlide'
import { customWebsitePackages } from '../data'
import { ZendeskAPI } from '../zendexconfig';


const CustomWebsite = () => {
  const handleZendeskChat = () => {
    ZendeskAPI('webWidget', 'open');
  };

  useEffect(() => {
    Fancybox.bind("[data-fancybox]", {
    });
}, []);

  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0);
  const images = [
    note,
    note1,
    note2,
  ];


  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImageIndex((prevIndex) =>
        (prevIndex + 1) % images.length
      );
    }, Math.floor(Math.random() * 3000) + 1000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (

    <div>

      <section className='custom852-banner'>
        <div className="container">
          <div className="row">
            <div className="characer">
              <h1>Explore The Features Of Custom Website Development  With <span className='bravo'>Virtual Design Crew</span></h1>
              <p>Break yourself from the crowd with an exclusive, custom-designed website developed just for you.
              </p>
              <div className="port-btn">
                <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" class="port-btn1">
                  contact us
                </a>

              </div>
            </div>

          </div>
        </div>
      </section>

      <section className='opener-include'>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">

              <div className="number-presword">
                <h3>
                  Look Into Our Programming Expertise for Customized Websites.

                </h3>
                <p>Our developers are equal to the task of creating a corporate application or improving an existing one. Virtual Design Crew creates unique online solutions that provide outcomes by concentrating on the unique needs and goals of our clients.</p>
              </div>
              <div className="main-button">
                <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
                  Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
                </a>
                <a className='second-btn' href="tel:510-474-0516" >
                  510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
                </a>


              </div>

            </div>

            <div className="col-md-6 col-12">
              <img className='img-fluid' src={m76} alt="no-img" />
            </div>

          </div>
        </div>
      </section>

      <section className='draw-side'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12">
              <div className="big-amount">
                <h2>
                  Our award-winning professionals & developers will help you in building your website from the ground up.
                </h2>
                <div className="bullets-line">
                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m72} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>End-To-End Website Design</h4>
                      <p>Whether you need end-to-end web design or front-and back-end development, our developers and designers will work with you to develop your creative website concept.</p>
                    </div>


                  </div>

                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m73} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>E-Commerce Development</h4>
                      <p>Want to create a unique online store? There's nowhere else to look. All of the top eCommerce platforms, including Shopify and Magento (Adobe Commerce), are worked with by our professionals.</p>
                    </div>


                  </div>

                  <div className="chriging-you">
                    <div className="monthing">
                      <img src={m74} alt="no-img" />
                    </div>

                    <div className="queen">
                      <h4>Magento Website Development</h4>
                      <p>Do you want to successfully position your eCommerce platform? Our Magento certified experts can assist you with developing, migrating, or updating your solution.</p>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="checking">
                <img className='righting-img' src={m71} alt="no-img" />
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='prim-porfolio'>
        <div className="container">
          <div className="row">
            <div className="noise-sharing">
              <h2>Our Work</h2>
              <p>We develop and build amazing experiences that fascinate audiences and drive continuous engagement.</p>
            </div>

            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div className="container">
                <div className="row pt-4 justify-content-between">
                  <div className="col-12 col-md-4">
                    <div className="invoke">
                      <a href={remort25} data-fancybox="gallery" >
                        <img src={morely} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="invoke">
                      <a href={r1} data-fancybox="gallery" >
                        <img src={troter} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="invoke">
                      <a href={top1} data-fancybox="gallery" >
                        <img src={top} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>

                </div>
                <div className="row pt-4 justify-content-between">
                  <div className="col-12 col-md-4">
                    <div className="invoke">
                      <a href={top2} data-fancybox="gallery" >
                        <img src={top3} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="invoke">
                      <a href={m4} data-fancybox="gallery" >
                        <img src={m3} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="invoke">

                      <a href={m5} data-fancybox="gallery" >
                        <img src={m6} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>

                </div>
                <div className="row pt-4 justify-content-between">
                  <div className="col-12 col-md-4">
                    <div className="invoke">
                      <a href={m7} data-fancybox="gallery" >
                        <img src={m8} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="invoke">
                      <a href={m9} data-fancybox="gallery" >
                        <img src={m10} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="invoke">
                      <a href={m12} data-fancybox="gallery" >
                        <img src={m11} className="img-fluid" alt="Image1" />
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>

          <div className="main-button expire-button">
            <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
              Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
            </a>
            <a className='second-btn' href="tel:510-474-0516" >
              510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
            </a>


          </div>
        </div>


      </section >

      <section className='working-process'>
        <div className="container">
          <div className="row pb-4 pt-4 g-4">

            <div className="laboris">

              <h5>How We Work </h5>
              <h3>It Is Simple To Work With Us</h3>

            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>1.</h3>
                <h4>Discovery & Planning:</h4>
                <p>Our first step is to understand your objectives, target market, and particular needs. We establish a customized plan that outlines the design and development methodology based on comprehensive discussions and analysis, assuring alignment with your business's objectives.</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>2.</h3>
                <h4>Design Concept Creation:</h4>
                <p>Our skilled artists turn your ideas into eye-catching visual designs. Our primary focus is on the user experience, integrating your brand aspects into a modern and user-friendly design that draws people in and improves usability.</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>3.</h3>
                <h4>Development & Coding:</h4>
                <p>Our developers develop clear, effective code to implement the accepted design. We make use of the latest innovations to guarantee a scalable, secure, and responsive website. Collaboration with clients and frequent updates ensure that the project's progress meets expectations.</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>4.</h3>
                <h4>Content Integration:</h4>
                <p>We include your text, pictures, and multimedia components while integrating the content and style seamlessly. Our team makes sure everything works as it should and loads quickly, which enhances the user experience in general.</p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>5.</h3>
                <h4>Testing & Quality Assurance:</h4>
                <p>To find and fix any bugs or inconsistencies, extensive testing is done on a variety of devices, browsers, and scenarios. We do user testing, security audits, and performance testing to guarantee a flawless website that exceeds user expectations. </p>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="trusted">
                <h3>6.</h3>
                <h4>Launch & Maintenance:</h4>
                <p>We launch your unique website and make it available to your targeted audience after you permit us. We provide after-launch maintenance and assistance to make sure your website stays safe, current, and able to adapt to shifting business requirements. 
</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='pricing-page'

        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${images[backgroundImageIndex]})`,
          transition: 'background-image 1s ease-in-out',
          position: 'relative',
        }}>


        <div className="opacity">
          <div className="container">
            <div className="row pb-5">
              <div class="pro-heading-1"><h2>OUR COMPETITIVE PACKAGES</h2>
                <p>You can create customized website with our exclusive website design services, that is necessary to meet your digital goals and improve your web page visibility.</p>

              </div>
              {customWebsitePackages.map((pkg, ind) => {
                return <div className="col-lg-4 col-12">

                  <PricingSlide pkg={pkg} handleZendeskChat={handleZendeskChat} />
                </div>
              })}
            </div>
          </div>
          <div className="main-button">
            <a className='frist-btn' data-bs-toggle="modal" href="#exampleModalToggle" role="button" >
              Connect with Us <FontAwesomeIcon className="roter-w1" icon={faArrowRight} />
            </a>
            <a className='second-btn' href="tel:510-474-0516" >
              510-474-0516<FontAwesomeIcon className='roter-w2' icon={faPhone} shake />
            </a>


          </div>
        </div>


      </section>


      <section className='center-first'>
        <div className="container">
          <div className="row">
            <div className="together">
              <h5 className='proces-new'>WHY BUSINESSES CHOOSE US</h5>
              <h3>Virtual Design Crew Due to Its Distinct Value As A Website Development Company</h3>
              <p>To build your online brand and produce quantifiable outcomes, we provide digital marketing services that may be used individually or in combined with other services to create an end-to-end plan.
              </p>
              <img className='img-fluid' src={m75} alt="no-img" />
            </div>
          </div>
        </div>

      </section>

      <Form />


    </div >
  )
}

export default CustomWebsite
