import React from 'react'

const PricingSlide = ({ pkg, handleZendeskChat }) => {
    return (
        <div className="main-custom-cards">
            <div className="main-oral">
                <h2>{pkg.title}</h2>
                <h6>{pkg.Original}<span>{pkg.originalPrice}</span></h6>
                <h3>{pkg.price}</h3>
            </div>
            <ul className="main-li">
                {pkg.listItem.map((list, index) => {
                    return <li key={index}>{list}</li>
                })}
            </ul>

            <a data-bs-toggle="modal" href="#exampleModalToggle" role="button" className='loop-2'>order now</a>
            <div className="order-now">
                <button className='commercial-rollup' onClick={handleZendeskChat}>Live Chat</button>
                <a href="tel:510-474-0516">510-474-0516</a>
            </div>
        </div>
    )
}

export default PricingSlide
