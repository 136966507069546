import React, { useEffect, useState } from 'react'

import { Link, NavLink} from "react-router-dom";
import coloredLogo from '../images/logo-color.png'
import $ from 'jquery'
import coloredLogo1 from '../images/hero-e-commerece.svg'
import coloredLogo2 from '../images/hero-wordpress.avif'
import coloredLogo3 from '../images/menu-webapp.svg'
import coloredlogo4 from '../images/hero-mobile-app-1.svg'

const Header = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [scoll, setScroll] = useState('main-header')
  const updateViewPortWidth = () => {
    setScreenWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateViewPortWidth)
    return () => {
      window.removeEventListener('resize', updateViewPortWidth)
    }
  }, [])
 
  $(window).scroll(function (e) {
    var scroll = $(window).scrollTop();
    if (scroll >= 100) {
      setScroll('main-header scroll')
    }
    else {
      setScroll('main-header')
    }
  })
  return (
    <div>
      <header className={scoll}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">


            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link className="navbrand-center d-lg-block  mx-auto navbar-brand" to="/">
              <img className="img-fluid" src={coloredLogo} alt="img" />
            </Link>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto ">
                <li className="nav-item nav-link1 custom-nav-item">
                  <NavLink className="nav-link  custom-nav-link" to="/">Home</NavLink>
                </li>
                <li className="nav-item nav-link1 custom-nav-item">
                  <NavLink className="nav-link  custom-nav-link" to="/AboutUs">About Us</NavLink>
                </li>
                {screenWidth < 500 && <>
                  <li className="nav-item nav-link1 custom-nav-item">
                    <NavLink className="nav-link  custom-nav-link" to="/CustomWebsite">Custom Website & Development</NavLink>
                  </li>
                  <li className="nav-item nav-link1 custom-nav-item">
                    <NavLink className="nav-link  custom-nav-link" to="/shopifywebsite">Shopify Website & Development</NavLink>
                  </li>
                  <li className="nav-item nav-link1 custom-nav-item">
                    <NavLink className="nav-link  custom-nav-link" to="/wordpress">Wordpress Website & Development</NavLink>
                  </li>
                  <li className="nav-item nav-link1 custom-nav-item">
                    <NavLink className="nav-link  custom-nav-link" to="/mobileapp">Mobile Applications</NavLink>
                  </li>
                </>

                }
                {screenWidth > 500 && <li className="nav-item nav-link1 custom-nav-item dropdown">
                  <NavLink className="nav-link custom-nav-link dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</NavLink>
                  <div className="dropdown-menu mega-menu">
                    <li>
                      <NavLink className="dropdown-item" to="/CustomWebsite"> <img className="img-fluid" src={coloredLogo3} alt="logo" /><div className="watch">
                        <p>Custom Website & Development</p>
                        <span>Custom business solutions to digital products</span>
                      </div>
                      </NavLink></li>
                    <li>
                      <NavLink className="dropdown-item" to="/shopifywebsite"> <img className="img-fluid" src={coloredLogo1} alt="logo" /><div className="watch">
                        <p>Shopify Website & Development</p>
                        <span>online store to custom intigartion</span>
                      </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/wordpress"> <img className="img-fluid" src={coloredLogo2} alt="logo" />
                        <div className="watch">
                          <p>Wordpress Website & Development</p>
                          <span>Our expertise lies in WordPress customization</span>
                        </div>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/mobileapp"> <img className="img-fluid" src={coloredlogo4} alt="logo" /> <div className="watch">
                        <p>Mobile Applications</p>
                        <span>Our focus is on creating outstanding mobile applications</span>
                      </div> </NavLink></li>
                  </div>
                </li>}
                <li className="nav-item nav-link1 custom-nav-item">
                  <NavLink className="nav-link  custom-nav-link" to="/portfolio">Portfolio</NavLink>
                </li>
                <li className="nav-item nav-link1 custom-nav-item">
                  <NavLink className="nav-link  custom-nav-link" to="/contact">Contact</NavLink>
                </li>
              </ul>
              <ul className="navbar-nav misson ms-auto ">
                <li className="nav-item custom-nav-item">
                  <a className='going-new' href="tel:510-474-0516">510-474-0516</a>
                </li>
                <li className="nav-item btn1 custom-nav-item">
                  <a className="nav-link custom-nav-link" data-bs-toggle="modal" href="#exampleModalToggle" role="button">
                    Get started</a>
                </li>


              </ul>

            </div>
          </nav>
        </div>
      </header>
    </div>
  )
}

export default Header
