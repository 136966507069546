import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom"
import './App.css';
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Header from './components/Header';
// import Cursor from './components/Cursor';
import Footer from './components/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Zendesk, { ZendeskAPI } from "./zendexconfig";
import Thankyou from './components/Thankyoumuch';
import TermsCondition from './pages/TermsCondition';
import Wordpress from './pages/Wordpress';
import CustomWebsite from './pages/CustomWebsite';
import ShopifyWebsite from './pages/ShopifyWebsite';
import MobileApp from './pages/MobileApp';
import AboutUs from './pages/Aboutus';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';
const ZENDESK_KEY = "f2a1408c-2b43-4a0d-beee-5594d6898370";



const App = () => {
    const location = useLocation()
    const handleLoaded = () => {
        ZendeskAPI("messenger", "open");
    };
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }
    }, [location])
    return (
        <>
            {/* <Cursor /> */}
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-condition" element={<TermsCondition />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/wordpress" element={<Wordpress />} />
                <Route path="/customwebsite" element={<CustomWebsite />} />
                <Route path="/shopifywebsite" element={<ShopifyWebsite />} />
                <Route path="/mobileapp" element={<MobileApp />} />



            </Routes>

            <Footer />
            <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
        </>
    )
}

export default App;
